
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.SiteContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.LeaderboardAboveHeader {
  display: none;
  @include mq($breakpoint-desktop) {
    display: block;
  }
}

.LeaderboardBelowHeader {
  display: none;
  @include mq($breakpoint-desktop) {
    display: block;
    margin-top: 32px;
  }
}
