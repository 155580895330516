
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Footer {
  background-color: $color-black-primary;
  color: $color-white-primary;

  .Socials {
    display: flex;
    list-style: none;
    justify-content: center;

    .SocialsIconButton {
      color: white;
      padding: 0 10px;

      @include mq($breakpoint-tablet) {
        padding: 0 40px;
      }
    }

    margin-top: 10px;
    margin-bottom: 10px;

    @include mq($breakpoint-desktop) {
      margin-top: -30px;
    }
  }

  @include mq($breakpoint-desktop) {
    padding-top: spacing(5);
  }
}

.FooterContentAbout {
  @include mq($breakpoint-desktop) {
    display: flex;
    width: 100%;
  }
}

.FooterContentAboutImageWrapper {
  margin-right: spacing(5);
  width: 150px;
}

.FooterContentAboutImage {
  box-shadow: 0 4px 28px 0 rgba(0, 0, 0, 0.4);
}

.FooterContentAboutInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  img {
    height: 20px;
  }
}

.FooterContentAboutText {
  margin-top: spacing(2.5);
}

.FooterContentAboutButton {
  margin-top: spacing(5);
}

.FooterNav {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.FooterNavColumn {
  border-bottom: $color-misc-divider 1px solid;
  padding: spacing(1.25) 0;
  @include mq($breakpoint-desktop) {
    border: none;
    padding: 0 0 0 spacing(2.5);
  }
}

.FooterNavColumnSocials {
  padding: 0;
  @include mq($breakpoint-desktop) {
    padding: 0 0 0 spacing(2.5);
  }
}

.FooterNavColumnHeader {
  width: 100%;
  display: flex;
  padding: spacing(1.25) 0;
  @include mq($breakpoint-desktop) {
    padding: 0 0 spacing(2);
  }
}

.Icon {
  &.rotated {
    transform: rotate(180deg);
  }
}

.FooterNavLink {
  width: 100%;
  padding: spacing(1) 0;
  color: $color-white-primary;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  @include mq($max: $breakpoint-desktop - 1) {
    display: block;
    padding: spacing(1.25) 0;
  }
}

.FooterNavLinkSocial {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-theme--secondary);
  border-radius: 50%;
  width: 48px;
  height: 48px;

  @include mq($breakpoint-desktop) {
    background: none;
    width: auto;
    height: auto;
    padding: spacing(1.25) 0;
    justify-content: flex-start;
  }

  @include mq($max: $breakpoint-desktop - 1) {
    &:hover,
    &:focus,
    &:active {
      background-color: $color-grey-secondary;
    }
  }
}

.FooterNavLinkSocialIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq($breakpoint-desktop) {
    width: 16px;
    height: auto;
    background: none;
    margin-right: spacing(1);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-2px);

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.FooterNavLinkSocialLabel {
  display: none;
  @include mq($breakpoint-desktop) {
    display: flex;
    align-items: center;
  }
}

.FooterNavListSocials {
  @include mq($max: $breakpoint-desktop - 1) {
    display: flex;
    flex-wrap: wrap;
    padding: spacing(4) 0;
    max-width: 232px;
    margin: 0 auto;
    li {
      flex-basis: 33.33%;
      display: flex;
      justify-content: center;
      &:nth-child(-n + 3) {
        margin-bottom: spacing(3.5);
      }
    }
  }
}

.FooterQuickLinksWrapper,
.FooterQuickLinks {
  display: flex;
  align-items: center;
  flex-direction: column;
  @include mq($breakpoint-desktop) {
    flex-direction: row;
  }
}

.FooterQuickLinks.right {
  margin-top: 30px;
  @include mq($breakpoint-desktop) {
    margin-left: auto;
    margin-top: 0;
  }
}

.FooterQuickLinksWrapper {
  padding: spacing(4) 0;
  @include mq($breakpoint-desktop) {
    padding-bottom: spacing(3);
    border: none;
  }
}

.FooterQuickLinksLogo {
  margin-bottom: 30px;
  @include mq($breakpoint-desktop) {
    margin-right: spacing(5.5);
    width: 72px !important;
    height: 72px !important;
    line-height: 72px !important;
    margin-bottom: 0;
  }
}

.FooterQuickLink {
  margin-bottom: spacing(2.5);
  @include mq($breakpoint-desktop) {
    margin-right: spacing(4.5);
    margin-bottom: 0;
    position: relative;

    &::after {
      content: '';
      display: block;
      background-color: var(--color-theme--secondary);
      width: 4px;
      height: 4px;
      border-radius: 100%;
      right: spacing(-2.5);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.FooterQuickLinkAnchor {
  color: $color-white-primary;
  text-decoration: underline;
  &:hover {
    color: var(--color-theme--secondary);
  }
}

.FooterQuickLink:last-child {
  margin-bottom: 0;
  @include mq($breakpoint-desktop) {
    margin-right: 0;

    &::after {
      display: none;
    }
  }
}
