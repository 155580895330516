
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.NavigationDropdown {
  color: $color-white-primary;

  @include mq($breakpoint-desktopNav) {
    min-width: 150px;
    margin-left: spacing(2);
    padding-top: spacing(1.25);
    position: absolute;
    right: 0;

    //.NavigationDropdown {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: 250ms ease-in-out clip-path;
    transform: translate3d(0, 0, 0);

    [class^='NavigationItem']:hover &,
    [class^='NavigationItem']:focus-within & {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
    //}
  }
}

.NavigationDropdownInner {
  background-color: $color-black-secondary;
  box-shadow: 0 4px 28px rgba($color-misc-black, 0.5);
}

.NavigationDropdownItem {
  display: block;
  color: $color-white-primary;
  text-decoration: none;
  padding: spacing(2.25);
  text-align: left;
  border-bottom: 1px solid $color-misc-divider;
  width: 100%;

  &:last-child {
    border-bottom: 0;
  }

  &:hover,
  &:focus {
    background-color: var(--color-theme--secondary);
  }

  @include mq($breakpoint-desktopNav) {
    padding: spacing(1.5);
  }
}

.Icon {
  margin-right: spacing(1);
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 3px;
}
