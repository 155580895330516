$main-spacing-mobile: spacing(1);
$main-spacing-tablet: spacing(4);
$main-spacing-desktop: spacing(4);

@mixin main-spacing() {
  padding-top: $main-spacing-tablet;
  padding-bottom: $main-spacing-tablet;

  @include mq($breakpoint-tablet) {
    padding-top: $main-spacing-tablet;
    padding-bottom: $main-spacing-tablet;
  }

  @include mq($breakpoint-desktopMedium) {
    padding-top: $main-spacing-desktop;
    padding-bottom: $main-spacing-desktop;
  }
}

@mixin main-spacing-top-reverse() {
  padding-top: 0;
}

@mixin main-spacing-bottom-reverse() {
  padding-bottom: 0;
}
