
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.UtilityNavigation {
  display: none;
  width: 100%;

  @include mq($breakpoint-desktopNav) {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: spacing(1) 0;
    // border-bottom: 1px solid var(--color-theme--secondary);
    align-self: flex-start;
    max-width: 100%;
    width: auto;
    margin-left: auto;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 200%;
      height: 2px; // Adjust the height as needed
      background: linear-gradient(
        to left,
        var(--color-theme--secondary),
        transparent
      );
      z-index: 2;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 100%;
      width: 160%;
      height: 2px; // Adjust the height as needed
      background: linear-gradient(
        to right,
        var(--color-theme--secondary) 0%,
        var(--color-theme--secondary) 75%,
        transparent
      );
      z-index: 2;
    }

    &.fullWidth {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.LinksWrapper {
  position: relative;
  display: inline-block;

  @include mq($breakpoint-desktopNav) {
    display: flex;
    justify-content: space-between;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 125%;
      height: calc(100% + 16px);
      background: linear-gradient(
        to left,
        var(--color-theme--primary) 0%,
        var(--color-theme--primary) 75%,
        transparent 100%
      );
      filter: brightness(1.25);
    }

    &::after {
      content: '';
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      width: calc(150%);
      height: calc(100% + 16px);
      // background: var(--color-theme--primary);
      background: linear-gradient(
        to right,
        var(--color-theme--primary) 0%,
        var(--color-theme--primary) 75%,
        transparent 100%
      );
      filter: brightness(1.25);
      z-index: -1;
    }
  }
}

.Links {
  display: block;
  margin-left: spacing(2);

  @include mq($breakpoint-desktopNav) {
    display: flex;
    align-items: center;
    margin-left: spacing(-3);

    & > *:last-child {
      padding-right: 0;
    }
  }

  .fullWidth & {
    margin-left: 0;
  }
}

.Link {
  text-decoration: none;
  position: relative;
  color: $color-white-primary;
  display: inline-flex;
  padding: spacing(1) spacing(3);
  line-height: 1;

  .inverse & {
    color: $color-white-primary;
  }

  &:hover,
  &:active,
  &.active {
    color: var(--color-theme--secondary);
  }
}

.SearchToggle {
  position: relative;
  min-height: spacing(5.5);
  display: inline-flex;
  padding-right: spacing(2.75);
  color: $color-white-primary;

  @include mq($breakpoint-desktopNav) {
    min-height: unset;
  }

  &:hover,
  &:active,
  &.active {
    color: var(--color-theme--secondary);
  }

  .fullWidth & {
    order: 2;
    align-self: flex-end;

    &::before {
      display: none;
    }
  }
}

.SearchIcon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  svg {
    fill: $color-white-primary;
  }

  .SearchToggle:hover &,
  .SearchToggle:focus & {
    svg {
      fill: var(--color-theme--secondary);
    }
  }
}
