
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.SignUpModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color-black-primary, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 100;
  height: 100%;
  padding: spacing(2.5) 0;
}

.Inner {
  position: relative;
  border-top: 3px solid var(--color-theme--secondary);
  background-color: $color-black-primary;
  width: 100%;
  max-width: 940px;
  padding: spacing(1.5) spacing(2.5) spacing(4);
  display: flex;
  flex-direction: column;
  max-height: 100%;

  @include mq($breakpoint-desktop) {
    border-width: 4px;
    padding: spacing(9) spacing(12.5) spacing(4);
  }

  .subscribed & {
    height: auto;
  }
}

.Heading {
  text-align: center;
  margin: spacing(2) 0 spacing(4);

  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(3);
  }
}

.Content {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Subscribed {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  color: $color-white-secondary;

  button {
    margin-top: spacing(2);
  }
}

.CTAWrapper {
  margin-top: auto;
}

.Button {
  position: absolute;
  top: spacing(3);
  right: spacing(3);
  background-color: $color-black-secondary;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);

  &:hover,
  &:focus {
    background-color: $color-grey-secondary;
  }
}

.FieldWrapper {
  margin-bottom: spacing(4);

  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(6);
  }
}

.CaptchaWrapper {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: spacing(4);

  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(6);
  }
}

.Submit {
  width: 100%;

  @include mq($breakpoint-desktop) {
    max-width: 330px;
  }
}
