@font-face {
  font-family: 'Rotunda';
  src: url('/public/font/RotundaRegular/font.woff2') format('woff2'),
    url('/public/font/RotundaRegular/font.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rotunda';
  src: url('/public/font/RotundaRegularItalic/font.woff2') format('woff2'),
    url('/public/font/RotundaRegularItalic/font.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rotunda';
  src: url('/public/font/RotundaMedium/font.woff2') format('woff2'),
    url('/public/font/RotundaMedium/font.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rotunda';
  src: url('/public/font/RotundaMediumItalic/font.woff2') format('woff2'),
    url('/public/font/RotundaMediumItalic/font.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rotunda';
  src: url('/public/font/RotundaBold/font.woff2') format('woff2'),
    url('/public/font/RotundaBold/font.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rotunda';
  src: url('/public/font/RotundaBoldItalic/font.woff2') format('woff2'),
    url('/public/font/RotundaBoldItalic/font.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rotunda';
  src: url('/public/font/RotundaExtraBold/font.woff2') format('woff2'),
    url('/public/font/RotundaExtraBold/font.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rotunda';
  src: url('/public/font/RotundaExtraBoldItalic/font.woff2') format('woff2'),
    url('/public/font/RotundaExtraBoldItalic/font.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Bodoni Moda';
  src: url('/public/font/BondoniModa/font.woff2') format('woff2'),
    url('/public/font/BodoniModa/font.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
