
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.SearchOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 20;
  background: $color-white-primary;
  @include mq($breakpoint-desktop) {
    padding: 0 spacing(5) spacing(3.5);
  }

  &.inverse {
    background: $color-black-primary;
  }
}

.SearchOverlayControlWrapper {
  display: flex;
  overflow: hidden;
  height: 100%;
  align-items: center;
  @include mq($breakpoint-desktop) {
    border-bottom: 1px solid $color-misc-divider;
    align-items: flex-end;
    padding-bottom: spacing(3);
  }
}

.SearchOverlayControl {
  font-size: $font-heading-eight;
  line-height: $font-height-120;
  font-weight: medium;
  flex: 1 0 auto;
  border: 0;
  position: relative;
  top: spacing(0.25);

  @include mq($breakpoint-desktop) {
    font-size: 28px;
  }

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: $color-black-primary;
  }

  .inverse & {
    background: $color-black-primary;
  }
}

.SearchOverlayLabel {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.SearchOverlaySubmit {
  position: relative;
  width: spacing(6);
  svg {
    transition: fill $duration-standard $easing-standard;
  }

  &[disabled] {
    opacity: 0.4;
  }
  @include mq($breakpoint-desktop) {
    width: unset;
    padding-right: spacing(1.5);
  }
}

.SearchOverlayHeading {
  padding: spacing(1.5) spacing(4);
  color: $color-grey-primary;
}

.SearchOverlayList {
  list-style: none;
}

.CloseIcon {
  &:hover,
  &:focus {
    svg {
      fill: $color-grey-primary;
    }
  }
}
