
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import '~backline-normalize/src/backline-normalize';
@import 'node_modules/react-modal-video/scss/modal-video.scss';
@import 'font-face';
@import 'ckeditor-custom';

// Let browsers know about available colour schemes
// https://drafts.csswg.org/css-color-adjust-1/#color-scheme-prop
// :root {
//   color-scheme: light dark;
// }

// Remove ALL animations/transitions by making them (almost) immediately skip
// to their finished state. May be overkill, but is a sensible start.
// https://css-tricks.com/revisiting-prefers-reduced-motion-the-reduced-motion-media-query/
@media screen and (prefers-reduced-motion: reduce), (update: slow) {
  * {
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.001ms !important;
  }
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-weight: normal;
  font-family: $font-body;
  font-size: 16px;
  line-height: 1.4; // 19.6px
  color: $color-black;

  @include mq($breakpoint-desktop) {
    font-size: 20px;
    line-height: 1.6; // 32px

    &.has-locked-scrolling-desktop {
      position: fixed;
      left: 0;
      right: 0;
    }
  }

  &.has-locked-scrolling {
    position: fixed;
    left: 0;
    right: 0;
  }
}

::selection {
  background: $color-black;
  color: $color-white;
  text-shadow: none;
}

*[data-has-anchor='true'] {
  display: block;
  position: relative;
  top: -250px;
  visibility: hidden;
}
