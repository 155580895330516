
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.NavigationItem {
  list-style: none;
  margin-left: 0;
  position: relative;

  @include mq($breakpoint-desktopNav) {
    display: inline-block;
  }

  &.desktopOnly {
    @include mq($max: $breakpoint-desktopNav - 1) {
      display: none;
    }
  }

  &.mobileOnly {
    @include mq($breakpoint-desktopNav) {
      display: none;
    }
  }
}

.NavigationItemLink {
  padding: spacing(2.25);
  text-decoration: none;
  color: $color-white-primary;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $color-misc-divider;
  width: 100%;

  @include mq($breakpoint-desktopNav) {
    display: block;
    padding: 0 spacing(2.25);
    border-bottom: 0;
    width: auto;

    .inverse & {
      color: $color-white-primary;
    }

    .event & {
      color: $color-black !important;
    }

    &:hover,
    &:active,
    .NavigationItem:focus-within & {
      color: var(--color-theme--secondary);

      svg {
        fill: var(--color-theme--secondary);
      }
    }
  }
}

.active {
  color: var(--color-theme--secondary);
}

.primary {
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: spacing(2);
    right: spacing(2);
    border-bottom: 1px solid $color-grey80;
  }

  .NavigationItemLink {
    font-size: spacing(2);
    font-weight: 700;

    @include mq($breakpoint-desktopNav) {
      padding: 0 spacing(3.25);
    }
  }
}

.saveSpace {
  > :first-child {
    display: none;
  }

  @include mq($breakpoint-desktopNav) {
    > :first-child {
      display: block;
    }
    > :last-child {
      display: none;
    }
  }

  @include mq($breakpoint-desktopLarge) {
    > :first-child {
      display: none;
    }
    > :last-child {
      display: block;
    }
  }
}

.Icon {
  margin-left: spacing(1);
  transition: 250ms ease-in-out transform;

  svg {
    fill: $color-white-primary;
  }

  .NavigationItem:hover &,
  .NavigationItem:focus-within & {
    transform: rotate(-180deg);
  }

  @include mq($max: $breakpoint-desktopNav - 1) {
    display: none !important;
  }
}

.Dropdown {
  display: none;

  .is-open & {
    display: block;
  }
}

@include mq($breakpoint-desktopNav) {
  .Dropdown {
    display: block;
  }
}

.DropdownToggle {
  position: absolute;
  top: spacing(1);
  right: 0;
  width: 100%;
  height: spacing(5.5);
  padding-right: spacing(2);
  text-align: right;
  color: $color-white-primary;
}

.DropdownIcon {
  &.flipped {
    transform: rotate(180deg);
  }

  svg {
    fill: $color-white-primary;
  }
}

.Title {
  @include mq($breakpoint-desktopMedium) {
    font-size: 14px !important;
    line-height: 140% !important;
  }
}
