
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.NavigationWrapper {
  text-align: center;
  position: relative;
  z-index: 2;
  height: 100%;

  @include mq($max: $breakpoint-desktopNav - 1) {
    text-align: left;
    padding: 0;
  }
}

.NavigationList {
  @include mq($max: $breakpoint-desktopNav - 1) {
    overflow: auto;
    height: 75vh;
  }

  @include mq($breakpoint-desktopNav) {
    display: flex;
    flex-direction: column;

    hr {
      background-color: var(--color-theme--secondary);
      height: 1px;
      border: 0;
      opacity: 0;
    }
  }
}

.NavigationWrapperInner {
  @include mq($breakpoint-desktopNav) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: spacing(4);
    padding-right: spacing(4);
    max-width: spacing(174 + 4);
  }
}

.Logo {
  display: none;
  @include mq($breakpoint-desktopNav) {
    display: flex;
    transform-origin: left;
    transition: transform ease-in-out 0.2s;

    img {
      height: 60px;
    }
  }

  &.small {
    @include mq($breakpoint-desktopNav) {
      transform: translateY(calc(130px * 0.2)) scale(0.7); // 126px is the height of the header (bottom)
    }
  }
}
