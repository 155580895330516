
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.HeaderContainer {
  z-index: 100;
  background: var(--color-theme--primary);
  text-align: center;
  overflow-x: clip;
  height: 40px;

  &.stickyWithAd {
    position: sticky;
    top: -190px;
  }
  &.sticky {
    position: sticky;
    top: calc((48px + 1px + 4px + 1px) * -1);
  }
  &.sticky.event {
    position: sticky;
    top: calc((48px + 1px + 4px + 1px + 80px) * -1);
  }
  &.sticky.disableSticky {
    position: sticky;
    top: 0;
  }
  // Don't understand the purpose of this
  @include mq($max: $breakpoint-desktopNav - 1) {
    position: sticky !important;
    top: 0 !important;
  }

  @include mq($breakpoint-desktopNav) {
    background: unset;
    height: 133.8px;
    &.event {
      height: 181.8px;
    }
  }
}
.Header {
  background: var(--color-theme--primary);
  box-shadow: 0 8px 18px rgba($color-black, 0.35);
}

.Top {
  padding: 0;
  border-bottom: 1px solid $color-misc-divider;
  position: relative;
  z-index: 3;
  background-color: var(--color-theme--primary);

  @include mq($breakpoint-desktopNav) {
    background-color: $color-black-primary;
    border-bottom: unset;
  }
}

.Bottom {
  @include mq($breakpoint-desktopNav) {
    transition: height ease-in-out 0.2s;
    border-top: 4px solid var(--color-theme--secondary);
  }
}

.Inner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mq($max: $breakpoint-desktopNav - 1) {
    margin: 0 spacing(-1);
  }
}

.Logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  img {
    height: 50px;

    @include mq($max: $breakpoint-desktopNav - 1) {
      height: spacing(3);
    }
  }
}

.HeaderButton {
  color: $color-white-primary;
  &:hover,
  &:focus {
    color: var(--color-theme--secondary);

    .inverse & {
      color: $color-white-primary;
    }
  }
}

.SearchToggle {
  position: relative;
  min-height: spacing(5.5);
  display: inline-block;
  padding-right: spacing(3);
  color: $color-white-primary;

  &:hover,
  &:active,
  &.active {
    color: var(--color-theme--secondary);
  }

  .fullWidth & {
    order: 2;
    align-self: flex-end;

    &::before {
      display: none;
    }
  }
}

.SearchIcon {
  margin-left: spacing(1);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  svg {
    fill: $color-white-primary;
  }

  .SearchToggle:hover &,
  .SearchToggle:focus & {
    svg {
      fill: var(--color-theme--secondary);
    }
  }
}

.NavigationListInner {
  @include mq($breakpoint-desktopNav) {
    padding: spacing(3) 0;

    li.last button {
      padding-right: 0;
    }
  }
}
