
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import '~backline-mixins/src/backline-mixins';
@import 'settings';

.EventNavigation {
  position: relative;
  z-index: 1; /* ducks the Event nav underneath the dropdowns in the Main nav */
  background-color: rgba($color-white-primary, 0.95);

  @include mq($breakpoint-desktopNav) {
    display: flex;
    padding: spacing(1) 0;
  }
}

.EventNavigationContainer {
  @include mq($breakpoint-desktop) {
    justify-content: space-between;
  }

  &.noLogo {
    @include mq($breakpoint-desktopNav) {
      justify-content: flex-end;
    }
  }

  @include mq($breakpoint-desktopNav) {
    display: flex;
    padding: 0 spacing(4);
    max-width: spacing(174 + 4);
  }
}

.EventNavigationList {
  display: flex;

  &.event {
    @include mq($breakpoint-desktopNav) {
      width: 100%;
    }
  }

  @include mq($breakpoint-desktopNav) {
    justify-content: flex-end;

    & > *:last-child > a,
    & > *:last-child > button {
      padding-right: 0;
    }
  }
}

.EventNavigationItemLink {
  background-color: $color-white-primary;
  color: $color-black-primary;

  @include mq($breakpoint-desktopNav) {
    background-color: unset;
  }

  &.cta {
    background-color: $color-black-primary;
    color: $color-white-primary;

    @include mq($breakpoint-desktopNav) {
      padding: 0 spacing(2.25) !important;
      margin-left: spacing(2.25);
    }

    &:hover {
      background-color: rgba($color-black-secondary, 0.75);
      color: $color-white-primary;
    }
  }

  &.live {
    background-color: #f00;
    color: $color-white-primary;
    animation: pulsate 1.5s infinite;

    @include mq($breakpoint-desktopNav) {
      padding: 0 spacing(2.25) !important;
      margin-left: spacing(2.25);
    }

    &:hover {
      color: $color-black-primary;
    }
  }

  @keyframes pulsate {
    0% {
      background-color: #f00; // Bright red
    }
    50% {
      background-color: #b51e00; // Darker red
    }
    100% {
      background-color: #f00; // Bright red
    }
  }
}

.EventNavigationDropdownIcon {
  color: $color-black-primary;
  svg {
    fill: $color-black-primary;
  }
}

.LogoWrapper {
  background-color: $color-white-primary;
  display: flex;
  align-items: center;
  padding: 0 spacing(2.25);
  min-height: 60px;

  @include mq($breakpoint-desktopNav) {
    background-color: unset;
    min-height: unset;
  }
}

.Logo {
  display: flex;
  height: 32px;
  position: relative;
  flex-grow: 1;

  img {
    object-fit: contain;
    object-position: left;

    @include mq($breakpoint-desktopNav) {
      left: 0;
    }
  }
}

.BackLink {
  gap: spacing(2);
  text-decoration: none;
  display: flex;
  align-items: center;
  color: $color-black-primary;
  flex-grow: 1;
  flex-shrink: 0;
}

.Back {
  display: flex;
  gap: spacing(1);
  align-items: center;

  svg {
    position: relative;
    top: -2px;
  }
}
