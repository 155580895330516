
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes darkPulse {
  0% {
    background-color: rgba($color-white-primary, 0.05);
  }
  50% {
    background-color: rgba($color-white-primary, 0.1);
  }
  100% {
    background-color: rgba($color-white-primary, 0.05);
  }
}

.UserNavigation {
  opacity: 1;
  animation: fadein 0.4s ease-in;

  @include mq($breakpoint-desktopNav) {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      border-left: 1px solid $color-misc-divider;
      top: 50%;
      height: 50%;
      transform: translateY(-50%);
    }
  }

  &.desktopOnly {
    @include mq($max: $breakpoint-desktopNav - 1) {
      display: none;
    }

    display: flex;
    align-items: center;
    height: 100%;
    margin-left: spacing(3);

    &.fullWidth {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &.mobileOnly {
    @include mq($breakpoint-desktopNav) {
      display: none;
    }

    display: flex;
    justify-content: flex-end;
    width: 100%;
    color: var(--color-theme--secondary);
    border-bottom: 1px solid $color-misc-divider;
    height: 67px;
    padding: 0 spacing(2);
  }
}

.Links {
  display: flex;
  justify-content: flex-end;
  margin-left: spacing(1);
  width: 165px;
  height: 100%;
  animation: fadein 0.4s ease-in;

  &.dynamicWidth {
    @include mq($breakpoint-desktopNav) {
      width: auto;
      min-width: 100px;
    }
  }

  @include mq($breakpoint-desktopNav) {
    width: 156px;
  }

  &.loading {
    animation: darkPulse 1s infinite ease-in-out;
  }

  .fullWidth & {
    margin-left: 0;
  }
}

.LinksInner {
  display: flex;
  align-items: center;
  gap: spacing(2);
  @include mq($breakpoint-desktopNav) {
    gap: spacing(0);
  }
}

.Link {
  text-decoration: none;
  position: relative;
  color: $color-white-primary;
  padding: spacing(0.5) spacing(2);
  display: flex;
  align-items: center;

  &.login {
    background-color: var(--color-theme--secondary);
    transform: perspective(1px) translateZ(0);
    transition: color 250ms ease-in-out;
    color: $color-white-primary !important;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      inset: 0;
      background: rgba($color-black, 0.1);
      transform: scaleX(0);
      transform-origin: 0 50%;
      transition-property: transform;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
    }

    &:hover,
    &:active,
    &.active {
      &::before {
        transform: scaleX(1);
      }
    }
  }

  &.logout {
    @include mq($breakpoint-desktopNav) {
      padding-right: 0;
    }
  }

  .inverse & {
    color: $color-white-primary;
  }

  &:hover,
  &:active,
  &.active {
    color: var(--color-theme--secondary);
  }

  & + & {
    @include mq($breakpoint-desktopNav) {
      // margin-left: spacing(2);
    }
  }
}

.Profile {
  width: max-content;
}
