
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.infinite-scroll-component__outerdiv {
  height: 100%;
}

.infinite-scroll-component {
  height: 100% !important;
  overflow-x: hidden !important;
}
