
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Main {
  flex: 1 0 auto;
  @include main-spacing;

  &.noSpacingTop {
    @include main-spacing-top-reverse;
  }

  &.noSpacingBottom {
    @include main-spacing-bottom-reverse;
  }
}
